import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from './Route'

import LogIn from 'pages/LogIn'
import ValidateAccount from 'pages/ValidateAccount'

import ForgotPasswordNew from 'pages/ForgotPasswordNew'
import ForgotPasswordReset from 'pages/ForgotPasswordReset'

import Users from 'pages/Users'
import NewUser from 'pages/NewUser'
// import Monitors from 'pages/Monitors'
// import Engagement from 'pages/Engagement'
import UserDetail from 'pages/UserDetail'
// import MonitorStudents from 'pages/MonitorStudents'
// import AdminMonitorStudents from 'pages/AdminMonitorStudents'

import Account from 'pages/Account'
// import Contact from 'pages/Contact'
// import Calendar from 'pages/Calendar'
import Dashboard from 'pages/Dashboard'
// import Notifications from 'pages/Notifications'

// import Incidences from 'pages/Incidences'
// import NewIncidence from 'pages/NewIncidence'

// import Goals from 'pages/Goals'
// import NewGoal from 'pages/NewGoal'

// import ThemesNew from 'pages/Themes/new'
// import ThemesList from 'pages/Themes/list'

// import SubjectsNew from 'pages/Subjects/new'
// import SubjectsList from 'pages/Subjects/list'

const App = () => (
  <React.Fragment>
    <Switch>
      {/* Public routes */}
      <Route path="/" exact component={LogIn} />
      <Route path="/validate_account" exact component={ValidateAccount} />
      <Route path="/forgot_password/reset" component={ForgotPasswordReset} />
      <Route path="/forgot_password/new" component={ForgotPasswordNew} />

      {/* Private routes */}
      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/users/new" exact component={NewUser} isPrivate />
      <Route path="/users/:userId" component={UserDetail} isPrivate />

      <Route path="/account" exact component={Account} isPrivate />

      {/* On not found */}
      <Redirect to="/" />
    </Switch>
  </React.Fragment>
)

export default App
