import React, { useEffect, useState } from 'react'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Helpers
import { format } from 'helpers/currencyFormat'

// Interfaces
import { KPI } from './interfaces'

// Styles
import {
  Container,
  Content,
  KPIsContainer,
  KPICard,
  KPITitle,
  KPIValue,
} from './styles'

import Layout from 'layouts/Logged'

const Dashboard: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [KPIs, setKPIs] = useState<KPI[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    api.get<KPI[]>('/dashboard').then(({ data }) => {
      setKPIs(data)
    })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Painel Principal"
          subTitle="Principais informações sobre a plataforma"
        />
        <Content>
          <SectionTitle>KPI&apos;s</SectionTitle>
          <KPIsContainer>
            {KPIs.map(kpi => (
              <KPICard key={`kpi-card-id-${kpi.label}`}>
                <KPITitle>{kpi.label}</KPITitle>
                <KPIValue>
                  {kpi.isCurrency
                    ? format(Number(kpi.value?.total))
                    : kpi.value?.total}
                </KPIValue>
              </KPICard>
            ))}
          </KPIsContainer>
        </Content>
      </Container>
    </Layout>
  )
}

export default Dashboard
