import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  overflow-y: scroll;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableContainer = styled.div`
  max-width: 100%;
  margin-bottom: 32px;
  position: relative;
`

export const ClearButton = styled.button`
  position: absolute;
  top: -48px;
  right: 0px;

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;

  border-radius: 16px;

  background-color: ${({ theme: { colors } }) => colors.lightGray};
  color: ${({ theme: { colors } }) => colors.darkerGray};

  transition: all 0.4s;

  &:hover {
    color: ${({ theme: { colors } }) => colors.darkerGray};
    background-color: ${({ theme: { colors } }) => colors.primary};
  }
`
