export function format(value: number, cutThousands: boolean = true): string {
  const shouldCut = cutThousands && (value >= 1000 || value <= -1000)

  return (
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(shouldCut ? value / 1000 : value) + `${shouldCut ? ' mil' : ''}`
  )
}
