import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import Swal from 'sweetalert2'

// Components
import Input from 'components/Input'
import Button from 'components/Button'
import Header from 'components/Header'
import ImageInput from 'components/ImageInput'
import SectionTitle from 'components/SectionTitle'

// Contexts
import { useAuth } from 'contexts/auth'

// Services
import api from 'services/api'

// Styles
import {
  Container,
  Content,
  Form,
  AvatarImage,
  AvatarContainer,
} from './styles'
import Layout from 'layouts/Logged'

const Account: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Contexts.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user, updateUserData } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Refs.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const userDataFormRef = useRef<FormHandles>(null)

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isPostingNewAvatar, setIsPostingNewAvatar] = useState(false)
  const [isUpdateAvatarEnabled, setIsUpdateAvatarEnabled] = useState(() =>
    user?.avatar?.url ? false : true,
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fileSubmitHandler = useCallback(
    (filePayload: any) => {
      if (!filePayload?.avatar) return

      const fileMultipartFormData = new FormData()
      fileMultipartFormData.append('avatar', filePayload.avatar)

      setIsPostingNewAvatar(true)

      api
        .post('app/avatars', fileMultipartFormData)
        .then(() => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Foto de perfil atualizada com sucesso!',
            icon: 'success',
            buttonsStyling: false,
            timer: 2000,
          }).then(() => {
            updateUserData()
            setIsUpdateAvatarEnabled(false)
          })
        })
        .catch(err => {
          console.trace('Error uploading avatar image. ', err)
          Swal.fire({
            title: 'Aviso',
            text: 'Não foi possível atualizar a sua foto de perfil agora.',
            icon: 'error',
            buttonsStyling: false,
          })
        })
        .finally(() => setIsPostingNewAvatar(false))
    },
    [updateUserData],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!user) return
    userDataFormRef.current?.setData({
      name: user.name,
      email: user.email,
      mobilePhone: user.phone_number,
    })
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Minha conta"
          subTitle="Imagem de perfil e dados pessoais"
        />
        <Content>
          <SectionTitle>Foto de perfil</SectionTitle>
          {user?.avatar?.url && !isUpdateAvatarEnabled && (
            <AvatarContainer>
              <AvatarImage width="256" height="256" src={user.avatar.url} />
              <Button
                title="alterar"
                type="button"
                onClick={() => setIsUpdateAvatarEnabled(true)}
              />
            </AvatarContainer>
          )}
          {isUpdateAvatarEnabled && (
            <Form onSubmit={fileSubmitHandler}>
              <ImageInput name="avatar" />
              <Button
                title="salvar"
                type="submit"
                isLoading={isPostingNewAvatar}
              />
            </Form>
          )}
          <SectionTitle>Cadastro</SectionTitle>
          <Form ref={userDataFormRef} onSubmit={d => console.log(d)}>
            <Input name="name" type="text" placeholder="Nome" />
            <Input name="email" type="email" placeholder="E-mail" disabled />
            <Input name="mobilePhone" placeholder="Telefone" />
            <Button title="editar" type="submit" disabled />
          </Form>
        </Content>
      </Container>
    </Layout>
  )
}

export default Account
