import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  overflow-y: scroll;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin: 32px 0px;
  }
`

export const UserDataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`

export const UserDataLabel = styled.p`
  font-weight: 100;
  color: rgba(255, 255, 255, 0.7);
  text-transform: lowercase;
  letter-spacing: 0.8px;
  font-size: 14px;
`

export const UserDataValue = styled.p`
  margin-left: 8px;
  color: #fff;
`
