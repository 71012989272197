import * as Yup from 'yup'

export const NewUserSchema = Yup.object().shape({
  name: Yup.string().min(5).max(80).required(),
  phoneNumber: Yup.string()
    .required('O número de telefone é obrigatório.')
    .matches(/\+55\s?\(?\d{2}\)?\s?\d{8,9}/gm, {
      message: 'Formato inválido. Use +55 (00) 000000000',
    }),
  email: Yup.string()
    .email('Por favor informe um e-mail válido.')
    .required('O email é obrigatório.'),
  isAdmin: Yup.boolean().required('O campo é obrigatório.'),
})
