import React, { useCallback, useMemo } from 'react'
import { Power } from 'react-feather'
import Swal from 'sweetalert2'

import { useAuth } from 'contexts/auth'

import {
  HeaderContainer,
  HeaderTitleContainer,
  HeaderTitle,
  HeaderSubTitle,
  HeaderButtonsContainer,
  ProfileContainer,
  ProfileAvatar,
  ProfileName,
  LogOutButton,
} from './styles'

interface HeaderProps {
  title: string
  subTitle: string
}

const Header: React.FC<HeaderProps> = ({ title, subTitle }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logOut, user } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Você relmente deseja fazer logout?',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  const avatarEndpoint = useMemo(() => {
    if (!user) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${user.name.replaceAll(' ', '+')}`
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubTitle>{subTitle}</HeaderSubTitle>
      </HeaderTitleContainer>
      <HeaderButtonsContainer>
        <ProfileContainer>
          <ProfileAvatar src={avatarEndpoint} alt="avatar" />
          <ProfileName>{user?.name}</ProfileName>
        </ProfileContainer>
        <LogOutButton onClick={confirmLogOut}>
          <Power size={20} strokeWidth={1} />
        </LogOutButton>
      </HeaderButtonsContainer>
    </HeaderContainer>
  )
}

export default Header
