import React from 'react'

import { AuthContextProvider } from './auth'
import { InterceptorProvider } from './interceptor'
import { StyledComponentsThemeProvider } from 'styles/ThemeProvider'

export const AppContextProvider: React.FC = ({ children }) => {
  return (
    <StyledComponentsThemeProvider>
      <AuthContextProvider>
        <InterceptorProvider>{children}</InterceptorProvider>
      </AuthContextProvider>
    </StyledComponentsThemeProvider>
  )
}

export default AppContextProvider
