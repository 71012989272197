import React, { useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core'
import Swal from 'sweetalert2'
import * as Yup from 'yup'

// Styles
import Layout from 'layouts/Unlogged'
import { FormContainer } from './styles'

// Components
import Button from 'components/Button'
import Input from 'components/Input'

// Schemas
import { ForgotPasswordNewSchema } from './schemas'

// Services
import api from 'services/api'

// Interfaces
import { ForgotPasswordPostPayload } from './interfaces'

const ForgotPasswordNew: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler: SubmitHandler<ForgotPasswordPostPayload> = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})

        await ForgotPasswordNewSchema.validate(formData, { abortEarly: false })

        await api.post('/public/forgot_password', formData)

        Swal.fire({
          title: 'Sucesso',
          text:
            'Seu pedido de redefinição de senha foi processado com sucesso. Acesse o seu e-mail para concluir.',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {}
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error: Yup.ValidationError) => {
              if (error.path) {
                validationErrors[error.path] = error.message
              }
            })
            formRef.current?.setErrors(validationErrors)
          }

          return
        }

        Swal.fire({
          title: 'Aviso',
          text:
            'Falha ao solicitar redefinição de senha. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <FormContainer ref={formRef} onSubmit={submitHandler}>
        <p>
          Informe o <span>seu email</span>
        </p>
        <div>
          <Input placeholder="E-mail" type="email" name="email" />
        </div>

        <div>
          <Button title="enviar" type="submit" />
          <Link to="/">voltar</Link>
        </div>
      </FormContainer>
    </Layout>
  )
}

export default ForgotPasswordNew
