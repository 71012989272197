import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

// Components
import Header from 'components/Header'
import Button from 'components/Button'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Interfaces
import { UserDataApiResponse } from './interfaces'

// Styles
import {
  Container,
  Content,
  UserDataRow,
  UserDataLabel,
  UserDataValue,
} from './styles'

import Layout from 'layouts/Logged'

const UserDetail: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | COnstants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const { userId } = useParams<{ userId?: string }>()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [user, setUser] = useState<UserDataApiResponse>()

  const [isResendingWelcomeEmail, setIsResendingWelcomeEmail] = useState(false)

  const resendWelcomeEmail = useCallback(() => {
    if (!user) return
    setIsResendingWelcomeEmail(true)
    api
      .post(`/users/${user.id}/resend_welcome_email`)
      .then(() => {
        Swal.fire({
          buttonsStyling: false,
          icon: 'success',
          title: 'Sucesso',
          text: 'E-mail reenviado com sucesso!',
        })
      })
      .catch(() => {
        Swal.fire({
          buttonsStyling: false,
          icon: 'error',
          title: 'Aviso',
          text: 'Falha ao enviar o e-mail.',
        })
      })
      .finally(() => setIsResendingWelcomeEmail(false))
  }, [user])

  const startWhatsappChat = useCallback((mobilePhone: string) => {
    if (!mobilePhone) return

    const onlyNumericString = mobilePhone.replace(/\D/g, '')
    if (!onlyNumericString.length) return

    try {
      window.open(`https://wa.me/${onlyNumericString}`, '_blank')
    } catch (error) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        timer: 3000,
        icon: 'error',
        title: 'Falha ao iniciar conversa.',
      })
    }
  }, [])

  const removeUser = useCallback(
    (userId: string) => {
      if (!userId) return
      api
        .delete(`/users/${userId}`)
        .then(() => {
          Swal.fire({
            buttonsStyling: false,
            title: 'Usuário removido com sucesso',
            icon: 'success',
            confirmButtonText: 'Voltar',
          }).then(() => {
            history.goBack()
          })
        })
        .catch(() => {
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            text: 'Não foi possível remover o usuário.',
          })
        })
    },
    [history],
  )

  const removeUserHandler = useCallback(
    (userId: string) => {
      if (!userId) return
      Swal.fire({
        title: 'Aviso',
        icon: 'warning',
        text: 'Essa ação é irreversível. Deseja prosseguir?',
        denyButtonText: 'Remover',
        showDenyButton: true,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        showConfirmButton: false,
      }).then(({ isDenied }) => {
        if (!isDenied) return
        removeUser(userId)
      })
    },
    [removeUser],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!userId) return

    api
      .get<UserDataApiResponse>(`/users/${userId}`)
      .then(({ data: userData }) => {
        setUser(userData)
      })
      .catch(() => {
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text: 'Perfil de usuário não encontrado. Retornando para a listagem.',
          icon: 'info',
        })
        history.replace('/users')
      })
  }, [history, userId])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Perfil"
          subTitle="Principais informações do desempenho do aluno"
        />
        <Content>
          <SectionTitle>Usuário</SectionTitle>

          <UserDataRow>
            <UserDataLabel>Nome: </UserDataLabel>
            <UserDataValue>{user?.name}</UserDataValue>
          </UserDataRow>
          <UserDataRow>
            <UserDataLabel>Email: </UserDataLabel>
            <UserDataValue>{user?.email}</UserDataValue>
          </UserDataRow>
          <UserDataRow>
            <UserDataLabel>Perfil</UserDataLabel>
            <UserDataValue>
              {user?.is_admin ? 'admin' : 'usuário'}
            </UserDataValue>
          </UserDataRow>
          <UserDataRow>
            <UserDataLabel>Telefone: </UserDataLabel>
            <UserDataValue>{user?.phone_number || 'N/L'}</UserDataValue>
          </UserDataRow>
          <UserDataRow>
            <UserDataLabel>Último login / atividade: </UserDataLabel>
            <UserDataValue>
              {!user?.last_login_at
                ? 'N/L'
                : format(new Date(user?.last_login_at!), 'dd/MM/yyyy HH:mm')}
            </UserDataValue>
          </UserDataRow>

          <SectionTitle>Ações</SectionTitle>
          {!user?.last_login_at && (
            <Button
              title="reenviar convite"
              isLoading={isResendingWelcomeEmail}
              onClick={resendWelcomeEmail}
            />
          )}
          {user?.phone_number && (
            <Button
              title="whatsapp"
              onClick={() =>
                user?.phone_number && startWhatsappChat(user.phone_number)
              }
            />
          )}
          <Button
            title="remover usuário"
            isLoading={isResendingWelcomeEmail}
            onClick={() => user && removeUserHandler(user.id)}
          />

          <Button title="voltar" onClick={history.goBack} />
        </Content>
      </Container>
    </Layout>
  )
}

export default UserDetail
