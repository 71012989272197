import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react'

import { useField } from '@unform/core'

import { PreviewImage } from './styles'

interface Props {
  name: string
  enablePreview?: boolean
}

type InputProps = JSX.IntrinsicElements['input'] & Props

const ImageInput: React.FC<InputProps> = ({
  name,
  enablePreview = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, registerField, defaultValue, error } = useField(name)
  const [preview, setPreview] = useState(defaultValue)

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) {
      setPreview(null)
    }

    const previewURL = URL.createObjectURL(file)

    setPreview(previewURL)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
        setPreview(null)
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value)
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      {enablePreview && preview && (
        <PreviewImage src={preview} alt="preview" width="256" height="256" />
      )}
      <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      {error && <span>{error}</span>}
    </>
  )
}

export default ImageInput
