import React, { useCallback } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { intersection } from 'lodash'

// Contexts
import { useAuth } from 'contexts/auth'

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const whitelistpages = ['/student/report']

const CustomRoute: React.FC<CustomRouteProps> = ({
  isPrivate = false,
  component: Component,
  path,
  ...rest
}) => {
  const { isLoggedIn, isLoading } = useAuth()

  const renderRule = useCallback(() => {
    // Auth Loading
    if (isLoading) return <p>Carregando ...</p>

    // Check if page is whitelisted
    if (intersection(whitelistpages, [path].flat(Infinity)).length)
      return <Component />

    // Logged and accessing private page
    if (isPrivate === isLoggedIn) return <Component />

    // Redirect if none condition above is satisfied
    return <Redirect to={{ pathname: isPrivate ? '/' : '/dashboard' }} />
  }, [Component, isLoading, isLoggedIn, isPrivate, path])

  return <Route {...rest} path={path} render={renderRule} />
}

export default CustomRoute
