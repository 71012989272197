import React, { useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup'

// Components
import Button from 'components/Button'
import Input from 'components/Input'

// Assets
import logo from 'assets/images/logo.png'

// Contexts
import { useAuth } from 'contexts/auth'
import { LogInPayload } from 'contexts/auth/interfaces'

// Schemas
import { LogInSchema } from './schemas'

// Styles
import { Container, FormContainer } from './styles'

const LogIn: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logIn, isLoading } = useAuth()
  const formRef = useRef<FormHandles>(null)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler: SubmitHandler<LogInPayload> = useCallback(
    async formData => {
      try {
        // Remove all previous errors
        formRef.current?.setErrors({})

        await LogInSchema.validate(formData, { abortEarly: false })

        // Validation passed
        logIn(formData)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          // Validation failed
          const validationErrors: { [key: string]: string } = {}
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error: Yup.ValidationError) => {
              if (error.path) {
                validationErrors[error.path] = error.message
              }
            })
            formRef.current?.setErrors(validationErrors)
          }
        }
      }
    },
    [logIn],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <img src={logo} alt="company logo" id="logo" />
      <div>
        <FormContainer ref={formRef} onSubmit={submitHandler}>
          <p>
            Acesse <span>sua conta</span>
          </p>
          <div>
            <Input placeholder="E-mail" type="email" name="email" />
            <Input placeholder="Senha" type="password" name="password" />
          </div>

          <div>
            <Button isLoading={isLoading} type="submit" title="entrar" />
            <Link to="/forgot_password/new">esqueci minha senha</Link>
          </div>
        </FormContainer>
      </div>
      <p>Finanças Dominadas | Todos os direitos reservados</p>
    </Container>
  )
}

export default LogIn
