import styled from 'styled-components'
import { Form as Unform } from '@unform/web'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;

  padding-top: 16px;

  button {
    margin-top: 32px;
  }

  > div {
    margin-bottom: 16px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin-right: 12px;

    &:last-child {
      background-color: ${({ theme: { colors } }) => colors.lightGray};
    }
  }
`
