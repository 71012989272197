import { Options } from 'material-table'

export const options: Options<any> = {
  exportAllData: true,
  filtering: true,
  debounceInterval: 600,
  search: false,
  headerStyle: {
    backgroundColor: '#302F37',
    color: '#FFFFFF',
    fontFamily: 'Mulish',
  },
  actionsCellStyle: {
    backgroundColor: '#302F37',
    color: '#FFFFFF',
    fontFamily: 'Mulish',
  },
  detailPanelColumnStyle: {
    backgroundColor: '#302F37',
    color: '#FFFFFF',
    fontFamily: 'Mulish',
  },
  searchFieldStyle: {
    color: '#FFFFFF',
    borderBottomColor: 'orange',
    fontFamily: 'Mulish',
  },
  rowStyle: {
    border: 0,
  },
  filterRowStyle: {
    color: '#FFFFFF',
  },
}
