import { Localization } from 'material-table'

export const localization: Localization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsPerPage: 'linhas',
    labelRowsSelect: 'linhas',
    lastTooltip: 'Última página',
    nextTooltip: 'Próxima página',
    previousTooltip: 'Página anterior',
    firstTooltip: 'Primeira página',
  },
  toolbar: {
    nRowsSelected: '{0} linha(s) selecionada(s)',
    searchPlaceholder: 'Buscar',
  },
  header: {
    actions: 'Ações',
  },
  body: {
    emptyDataSourceMessage: 'Nenhum resultado para exibir',
    filterRow: {
      filterTooltip: 'Filtros',
    },
    addTooltip: 'Criar',
    deleteTooltip: 'Deletar',
    editRow: {
      cancelTooltip: 'Cancelar',
      deleteText: 'Deletar',
      saveTooltip: 'Salvar',
    },
    editTooltip: 'Editar',
  },
}
