import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import Swal from 'sweetalert2'

// Components
import Input from 'components/Input'
import Button from 'components/Button'
import Header from 'components/Header'
import SelectInput from 'components/SelectInput'
import SectionTitle from 'components/SectionTitle'

// Schemas
import { NewUserSchema } from './schemas'

// Services
import api from 'services/api'

// Styles
import { Container, Content, Form, ButtonsContainer } from './styles'
import Layout from 'layouts/Logged'
import { NewUserFormData } from './interfaces'

const NewUser: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Contexts.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Refs.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const userDataFormRef = useRef<FormHandles>(null)

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isSubmitting, setIsSubmitting] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const clearForm = useCallback(() => {
    userDataFormRef.current?.reset()
  }, [])

  const goToUsersList = useCallback(() => {
    history.push('/users')
  }, [history])

  const createNewUser = useCallback(formData => {
    setIsSubmitting(true)
    api
      .post('/users', formData)
      .then(() => {
        userDataFormRef.current?.reset()

        Swal.fire({
          buttonsStyling: false,
          title: 'Sucesso',
          text: 'Cadastro realizado com sucesso',
          icon: 'success',
        })
      })
      .catch(err => {
        console.trace(err)
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text: 'O cadastro não pode ser feito concluído.',
          icon: 'error',
        })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [])

  const submitHandler: SubmitHandler<NewUserFormData> = useCallback(
    formData => {
      NewUserSchema.validate(formData, { abortEarly: false })
        .then(createNewUser)
        .catch(err => {
          console.trace(err)

          if (err instanceof Yup.ValidationError) {
            const validationErrors: { [key: string]: string } = {}
            if (err instanceof Yup.ValidationError) {
              err.inner.forEach((error: Yup.ValidationError) => {
                if (error.path) {
                  validationErrors[error.path] = error.message
                }
              })
              userDataFormRef.current?.setErrors(validationErrors)
            }

            return
          }

          Swal.fire({
            title: 'Aviso',
            text:
              'Não foi possível criar o usuário. Por favor tente novamente.',
            buttonsStyling: false,
          })
        })
    },
    [createNewUser],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    userDataFormRef.current?.setFieldValue('mobilePhone', '+55 ')
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Cadastro de Usuários"
          subTitle="Insira novos usuários e administradores"
        />
        <Content>
          <SectionTitle>Listagem</SectionTitle>
          <Button title="exibir" type="button" onClick={goToUsersList} />
          <SectionTitle>Cadastro</SectionTitle>
          <Form ref={userDataFormRef} onSubmit={submitHandler}>
            <SelectInput
              name="isAdmin"
              placeholder="Perfil"
              options={[
                { value: true, label: 'Admin e usuário' },
                { value: false, label: 'Apenas usuário' },
              ]}
            />
            <Input
              name="name"
              type="text"
              placeholder="Nome completo"
              required
            />
            <Input name="email" type="email" placeholder="E-mail" required />
            <Input name="phoneNumber" placeholder="Telefone" required />
            <ButtonsContainer>
              <Button
                title="cadastrar"
                type="submit"
                isLoading={isSubmitting}
              />
              <Button title="limpar" type="button" onClick={clearForm} />
            </ButtonsContainer>
          </Form>
        </Content>
      </Container>
    </Layout>
  )
}

export default NewUser
