import React, { useCallback, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core'
import Swal from 'sweetalert2'
import * as Yup from 'yup'

// Styles
import Layout from 'layouts/Unlogged'
import { FormContainer } from './styles'

// Components
import Button from 'components/Button'
import Input from 'components/Input'

// Schemas
import { ForgotPasswordNewSchema } from './schemas'

// Services
import api from 'services/api'

// Interfaces
import { ForgotPasswordPostPayload } from './interfaces'

// Helpers
import { parseYupSchemaError } from 'helpers/parseYupSchemaError'

const ForgotPasswordReset: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const location = useLocation<{ token: string }>()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler: SubmitHandler<ForgotPasswordPostPayload> = useCallback(
    async formData => {
      try {
        const query = new URLSearchParams(location.search)
        const resetPasswordToken = query.get('token')
        if (!resetPasswordToken) return

        formRef.current?.setErrors({})

        await ForgotPasswordNewSchema.validate(formData, { abortEarly: false })

        await api.put('/public/forgot_password', {
          ...formData,
          resetPasswordToken,
        })

        Swal.fire({
          title: 'Sucesso',
          text: 'Senha redefinida com sucesso.',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = parseYupSchemaError(err)
          formRef.current?.setErrors(validationErrors)
          return
        }

        Swal.fire({
          title: 'Aviso',
          text:
            'Falha ao solicitar redefinição de senha. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history, location.search],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Redirect user to login if no token is presented as query param.
     */
    const query = new URLSearchParams(location.search)
    const resetPasswordToken = query.get('token')
    if (!resetPasswordToken) return history.replace('/')
  }, [history, location])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <FormContainer ref={formRef} onSubmit={submitHandler}>
        <p>
          Digita a sua <span>nova senha</span>
        </p>
        <div>
          <Input placeholder="Senha" type="password" name="password" />
          <Input
            placeholder="Confirma a senha"
            type="password"
            name="passwordConfirmation"
          />
        </div>

        <div>
          <Button title="redefinir" type="submit" />
          <Link to="/">voltar</Link>
        </div>
      </FormContainer>
    </Layout>
  )
}

export default ForgotPasswordReset
