import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core'
import Swal from 'sweetalert2'
import * as Yup from 'yup'

// Components
import Button from 'components/Button'
import Input from 'components/Input'

// Types
import { ForgotPasswordPostPayload } from './interfaces'

// Helpres
import { parseYupSchemaError } from 'helpers/parseYupSchemaError'

// Schemas
import { ValidateAccountSchema } from './schemas'

// Services
import api from 'services/api'

// Styles
import Layout from 'layouts/Unlogged'
import { FormContainer } from './styles'

const ValidateAccount: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const location = useLocation<{ token: string }>()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler: SubmitHandler<ForgotPasswordPostPayload> = useCallback(
    async formData => {
      try {
        const query = new URLSearchParams(location.search)
        const validationEmailToken = query.get('token')
        if (!validationEmailToken) return

        formRef.current?.setErrors({})

        await ValidateAccountSchema.validate(formData, { abortEarly: false })

        await api.put('/public/validate_account', {
          ...formData,
          validationEmailToken,
        })

        Swal.fire({
          title: 'Sucesso',
          text:
            'Conta validada com sucesso. Você já pode fazer o seu login. Seja bem vindo!',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const validationErrors = parseYupSchemaError(error)
          formRef.current?.setErrors(validationErrors)
          return
        }

        Swal.fire({
          title: 'Aviso',
          text: 'Falha ao validar a sua conta. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history, location.search],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Redirect user to login if no token is presented as query param.
     */
    const query = new URLSearchParams(location.search)
    const validationEmailToken = query.get('token')
    if (!validationEmailToken) return history.replace('/')
  }, [history, location])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <FormContainer ref={formRef} onSubmit={submitHandler}>
        <p>
          Defina a <span>sua senha</span>
        </p>
        <div>
          <Input placeholder="Senha" type="password" name="password" />
          <Input
            placeholder="Confirme a senha"
            type="password"
            name="passwordConfirmation"
          />
        </div>

        <div>
          <Button title="definir" type="submit" />
        </div>
      </FormContainer>
    </Layout>
  )
}

export default ValidateAccount
