import styled, { css } from 'styled-components'
import { darken, transparentize } from 'polished'

const disabledButtonStyle = css`
  background: ${({ theme: { colors } }) => colors.mediumGray};
  &:hover {
    background: ${({ theme: { colors } }) => colors.mediumGray};
    text-decoration: none;
  }
`

export const StyledButton = styled.button`
  height: 44px;
  width: 176px;
  border-radius: 80px;

  background: ${({ theme: { colors } }) => colors.primary};

  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${({ theme: { colors } }) => colors.darkerGray};
  text-transform: uppercase;

  transition: background 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.02);
    background: ${({ theme: { colors } }) => darken(-0.05, colors.primary)};
    box-shadow: 0px 0px 12px 4px
      ${({ theme: { colors } }) => transparentize(0.75, colors.black)};
  }

  &:active {
    transform: scale(0.98);
  }

  ${({ disabled }) => disabled && disabledButtonStyle}
`
