import React, { ButtonHTMLAttributes } from 'react'

import { StyledButton } from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  isLoading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  title,
  isLoading = false,
  disabled,
  ...rest
}) => {
  return (
    <StyledButton disabled={disabled || isLoading} {...rest}>
      {isLoading ? 'carregando ...' : title}
    </StyledButton>
  )
}

export default Button
