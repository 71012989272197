import styled from 'styled-components'
import { transparentize } from 'polished'

export const StyledInput = styled.input`
  display: flex;
  width: 90%;
  max-width: 380px;
  height: 48px;
  min-height: 48px;
  background: ${({ theme: { colors } }) => transparentize(0.92, colors.white)};
  border-radius: 90px;
  outline: 0;
  border: 0;
  padding-left: 16px;
  color: ${({ theme: { colors } }) => transparentize(0.1, colors.white)};

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    color: ${({ theme: { colors } }) => colors.mediumGray};
  }

  + input {
    margin-top: 16px;
  }

  + span {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.lightGray};
  }
`
