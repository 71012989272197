import * as Yup from 'yup'

export function parseYupSchemaError(yupError: Yup.ValidationError) {
  const validationErrors: { [key: string]: string } = {}

  yupError.inner.forEach((error: Yup.ValidationError) => {
    if (error.path) {
      validationErrors[error.path] = error.message
    }
  })

  return validationErrors
}
