import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PieChart, Settings, Users, List as ListIcon } from 'react-feather'

import logo from 'assets/images/logo.png'

import { Container, List, ListItem, Logo } from './styles'

const Sidebar: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { pathname } = useLocation()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */
  const sideBarItems = useMemo(
    () => ({
      primary: [
        {
          isExternal: false,
          label: 'Dashboard',
          path: '/dashboard',
          icon: <PieChart size={16} />,
        },
        {
          isExternal: false,
          label: 'Usuários',
          path: '/users/new',
          icon: <Users size={16} />,
        },
        {
          isExternal: false,
          label: 'Categorias',
          path: '/themes/new',
          icon: <ListIcon size={16} />,
        },
      ],
      secondary: [
        {
          isExternal: false,
          label: 'Minha Conta',
          path: '/account',
          icon: <Settings size={16} />,
        },
      ],
    }),
    [],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <Logo src={logo} alt="side bar logo" draggable={false} />
      <List>
        {sideBarItems.primary.map(item => {
          if (item.isExternal) {
            return (
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                key={item.path}
              >
                <ListItem
                  key={item.label}
                  isActive={item.path.startsWith(pathname)}
                >
                  {item.icon}
                  <p>{item.label}</p>
                </ListItem>
              </a>
            )
          }

          return (
            <Link to={item.path} key={item.path}>
              <ListItem
                key={item.label}
                isActive={item.path.startsWith(pathname)}
              >
                {item.icon}
                <p>{item.label}</p>
              </ListItem>
            </Link>
          )
        })}
        <hr />
        {sideBarItems.secondary.map(item => {
          if (item.isExternal) {
            return (
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                key={item.path}
              >
                <ListItem key={item.label} isActive={pathname === item.path}>
                  {item.icon}
                  <p>{item.label}</p>
                </ListItem>
              </a>
            )
          }

          return (
            <Link to={item.path} key={item.path}>
              <ListItem key={item.label} isActive={pathname === item.path}>
                {item.icon}
                <p>{item.label}</p>
              </ListItem>
            </Link>
          )
        })}
      </List>
    </Container>
  )
}

export default Sidebar
