import React, { createContext } from 'react'
import Swal from 'sweetalert2'

import api from 'services/api'

import { useAuth } from 'contexts/auth'

const InterceptorContext = createContext({})

export const InterceptorProvider: React.FC = ({ children }) => {
  const { logOut } = useAuth()

  api.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // eslint-disable-next-line no-console
      if (error?.response?.status === 401) {
        Swal.fire({
          buttonsStyling: false,
          title: 'Sua sessão expirou.',
          html:
            'Você será direcionado para a tela de login. Por favor inicie uma nova sessão para continuar usando a plataforma.',
        }).then(() => logOut())
      }

      return Promise.reject(error)
    },
  )

  return (
    <InterceptorContext.Provider value={{}}>
      {children}
    </InterceptorContext.Provider>
  )
}
