import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    // Brand Colors
    primary: '#22ff41',

    // Grays Shades
    black: '#000000',
    darkerGray: '#1B1B1B',
    darkGray: '#302F37',
    mediumGray: '#838383',
    lightGray: '#A8A8A8',
    white: '#FFFFFF',
  },
}

export const StyledComponentsThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)
