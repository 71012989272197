import React, { useRef, useEffect } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { transparentize } from 'polished'

import { ErrorSpan } from './styles'

interface Props extends SelectProps<OptionTypeBase, true> {
  name: string
}

const SelectInput: React.FC<Props> = ({ name, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      },
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value)
      },
      clearValue: ref => {
        ref.select.clearValue()
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        // isClearable
        noOptionsMessage={() => 'Nenhum item encontrado'}
        classNamePrefix="react-select"
        styles={{
          container: base => ({
            ...base,
            width: '90%',
            height: 48,
          }),
          control: base => ({
            ...base,
            maxWidth: 380,
            width: '100%',
            display: 'flex',
            height: 48,
            backgroundColor: transparentize(0.92, '#fff'),
            color: transparentize(0.1, '#fff'),
            borderRadius: 90,
            outline: 0,
            border: 0,
            paddingLeft: 16,
            paddingRight: 8,
            '&:hover': {
              borderColor: 'transparent',
            },
            boxShadow: 'none',
          }),
          input: base => ({
            ...base,
            color: '#FFF',
          }),
          indicatorSeparator: base => ({
            ...base,
            backgroundColor: 'transparent',
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#22ff41',
          }),
          menuList: base => ({
            ...base,
            backgroundColor: 'transparent',
            zIndex: 2000,
            borderRadius: 16,
          }),
          menu: base => ({
            ...base,
            borderRadius: 16,
            color: '#22ff41',
            backgroundColor: '#fff',
            zIndex: 3000,
          }),
          singleValue: base => ({
            ...base,
            color: transparentize(0.1, '#fff'),
          }),
          option: (base, props) => ({
            ...base,
            backgroundColor: props.isSelected ? '#22ff41' : '#FFF',
            color: '#000',
          }),
        }}
        {...rest}
      />
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </>
  )
}

export default SelectInput
