import styled from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  align-items: center;

  height: 100%;
  width: 292px;

  padding-top: 32px;

  background: #2a2a2d;
`

export const Logo = styled.img`
  width: 60%;
  margin: 1rem 0;
`

export const List = styled.ul`
  width: 100%;

  margin-top: 16px;

  overflow-x: scroll;

  hr {
    border-top: 1px solid ${transparentize(0.94, '#dfe0eb')};
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;

    margin-bottom: 16px;
  }

  > a {
    text-decoration: none;
  }
`

export const ListItem = styled.li<{ isActive: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 56px;
  width: 100%;

  padding-left: 20%;

  cursor: pointer;

  transition: background 0.4s, color 0.4s;

  color: #595a63;
  user-select: none;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background: ${transparentize(0.92, '#dde2ff')};
    border-left: 4px solid #dde2ff;
    color: #dde2ff;
    padding-left: calc(20% - 4px);
  }

  ${({ isActive }) =>
    isActive &&
    `
    background: ${transparentize(0.92, '#dde2ff')};
    border-left: 4px solid #dde2ff;
    color: #dde2ff;
    padding-left: calc(20% - 4px);
  `}
`
